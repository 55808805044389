

































































import { ref, reactive, defineComponent } from '@vue/composition-api'
import axios from '@/service'
export interface SnakerBarMsg {
  id?: string
  msg?: string
  enable?: boolean
  color?: string
}
export default defineComponent({
  name: 'signup',
  setup(_, { root: { $router } }) {
    const account = ref('')
    const password = ref('')
    const verPassword = ref('')
    const snackbar = reactive<Array<SnakerBarMsg>>([
      {
        id: 'value-null',
        msg: '账号或密码不能为空',
        enable: false,
      },
      {
        id: 'pass-diff',
        msg: '两次输入密码不一致',
        enable: false,
      },
      {
        id: 'reg-succ',
        msg: '注册成功',
        enable: false,
        color: 'success',
      },
      {
        id: 'reg-fail',
        msg: '注册失败，请稍后重试',
        enable: false,
        color: 'danger',
      },
    ])
    const findId = (snackbar: Array<SnakerBarMsg>, id: string) => {
      let index = -1
      snackbar.forEach((v, idx) => {
        if (v.id === id) {
          index = idx
        }
      })
      return index
    }
    const verForm = () => {
      if (account.value.trim() === '' || password.value.trim() === '') {
        const item = snackbar[findId(snackbar, 'value-null')]
        item.enable = true
        console.log('hh')
        return false
      }
      if (password.value !== verPassword.value) {
        const item = snackbar[findId(snackbar, 'pass-diff')]
        item.enable = true
        return false
      }
      return true
    }
    const gotoMenu = (ref: 'signup' | 'login') => {
      if (ref === 'signup') {
        $router.push('/Signup')
      } else if (ref === 'login') {
        $router.push('/login')
      }
    }
    const handleLogin = async () => {
      if (!verForm()) {
        return
      }
      const {
        data: { success },
      } = await axios.post('/auth/register', {
        account: account.value,
        password: password.value,
      })
      if (success === true) {
        const item = snackbar[findId(snackbar, 'reg-succ')]
        item.enable = true
        setTimeout(() => {
          gotoMenu('login')
        }, 1000)
      } else {
        const item = snackbar[findId(snackbar, 'reg-fail')]
        item.enable = true
        password.value = ''
        account.value = ''
      }
    }
    return {
      account,
      password,
      verPassword,
      handleLogin,
      verForm,
      snackbar,
      gotoMenu,
    }
  },
})
